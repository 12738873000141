/**
 * @file This file defines various enums used for analytics events in the application.
 *
 */

/**
 * @type {EventNames}
 */
export const EventNames = Object.freeze({
  BUTTON_CLICKED: "ButtonClicked",
  PAGE_VIEWED: "PageViewed",
  USER_REGISTERED: "UserRegistered",
  // Add more event names as needed
});

/**
 * @type {EventProperties}
 */
export const EventProperties = Object.freeze({
  BUTTON_ID: "buttonId",
  BUTTON_TEXT: "buttonText",
  PAGE_NAME: "pageName",
  TIMESTAMP: "timestamp",
  USER_ID: "userId",
  USER_ROLE: "userRole",
  COMPANY: "company",
  ADDITIONAL_DATA: "additionalData",
  // Add more properties as needed
});

/**
 * @type {PageNames}
 */
export const PageNames = Object.freeze({
  HOME_PAGE: "HomePage",
  // Add more page names as needed
});

/**
 * @type {ButtonIds}
 */
export const ButtonIds = Object.freeze({
  SIGNUP_BUTTON: "SignupButton",
  // Add more button IDs as needed
});
