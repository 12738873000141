/**
 * @file MixpanelService.js
 *
 * The MixpanelService is a utility for integrating Mixpanel analytics into the application.
 * It provides reusable methods to initialize Mixpanel, track custom events, identify users, and other
 * analytics-related functionality.
 *
 * Start using by importing the `MixpanelService` in Vue components and calling the methods as needed.
 */

import mixpanel from "mixpanel-browser";
import { EventNames } from "@/utilities/AnalyticsEnums";

const MixpanelService = {
  /**
   * Initializes Mixpanel with the provided project token.
   * @param {string} token - The Mixpanel project token.
   */
  init: (token) => {
    mixpanel.init(token);
  },

  /**
   * Vue plugin install function.
   * @param {Object} app - The Vue app instance.
   * @param {Object} options - The options object.
   * @param {string} options.token - The Mixpanel project token. This is defined as an environment variable.
   */
  install: (app, options) => {
    if (!options.token) {
      console.error("Mixpanel project token is required");
      return;
    }
    // Initialize Mixpanel with the provided project token
    MixpanelService.init(options.token);
  },

  /**
   * Tracks a custom event with optional properties.
   * @param {string} eventName - The name of the event to track.
   * @param {Object} [properties={}] - Optional properties to include with the event.
   */
  trackEvent: (eventName, properties = {}) => {
    mixpanel.track(eventName, properties);
  },

  /**
   * Identifies a user by their unique ID.
   * @param {string} userId - The unique ID of the user.
   */
  identifyUser: (userId) => {
    mixpanel.identify(userId);
  },

  /**
   * Sets user-specific properties for analytics.
   * @param {Object} properties - The properties to set for the user.
   */
  setUserProperties: (properties) => {
    mixpanel.people.set(properties);
  },

  /**
   * Creates an alias for a user.
   * @param {string} alias - The alias to create for the user.
   */
  alias: (alias) => {
    mixpanel.alias(alias);
  },

  /**
   * Resets the Mixpanel state, useful for logging out users.
   */
  reset: () => {
    mixpanel.reset();
  },

  /**
   * Starts a timer for an event.
   * @param {string} eventName - The name of the event to time.
   */
  timeEvent: (eventName) => {
    mixpanel.time_event(eventName);
  },

  /**
   * Tracks a page view with the given page name.
   * @param {string} pageName - The name of the page to track.
   */
  trackPageView: (pageName) => {
    mixpanel.track(EventNames.PAGE_VIEWED, { page: pageName });
  },
};

export default MixpanelService;
